<template>
  <common-trs-card class="pa-2 pb-0 mb-2">
    <validation-observer
      ref="observer"
      v-slot="{ valid }"
    >
      <v-card-text class="pa-0">
        <v-form>
          <financing-round-common-form
            v-model="financingRound"
            :financing-round-id="financingRoundId"
            :mode="mode"
            :is-submit="isSubmit"
            :internal-dialog="internalDialog"
            @navToList="$emit('navToList', true)"
          />
        </v-form>
      </v-card-text>
      <v-row class="mx-1">
        <v-col>
          <v-divider />
          <div class="d-flex justify-space-between mt-2">
            <common-trs-btn
              type="secondary"
              medium
              class="pull-right mr-2"
              text
              :to="{name: 'FinancingRound'}"
            >
              Cancel
            </common-trs-btn>
            <common-trs-btn
              type="primary"
              medium
              class="white--text pull-right"
              text
              :disabled="!valid"
              :loading="loading"
              @click="save"
            >
              Save
            </common-trs-btn>
          </div>
        </v-col>
      </v-row>
    </validation-observer>
  </common-trs-card>
</template>
<script>
  import FinancingRoundCommonForm from '@/components/common/captable/components/forms/FinancingRoundForm'

  export default {
    name: 'FinancingRoundForm',

    components: {
      FinancingRoundCommonForm,
    },

    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      financingRound: {
        type: Object,
        required: false,
      },
      mode: {
        type: String,
        default: 'create',
      },
      financingRoundId: {
        type: String,
      },
      isSubmit: {
        type: Boolean,
        default: false,
      },
      internalDialog: {
        type: Boolean,
        default: false,
      },
    },

    methods: {
      save () {
        this.$emit('save', this.financingRound)
      },
    },
  }
</script>
