var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"ma-0 mt-4"},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":'Financing Round Name',"rules":{ required: true, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"label":"Financing round name","color":"primary","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.internalValue.name),callback:function ($$v) {_vm.$set(_vm.internalValue, "name", $$v)},expression:"internalValue.name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":'Funding Type',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Funding Type","placeholder":"Funding type","items":_vm.CONSTS.FINANCING_ROUND_FUNDING_TYPES,"error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.internalValue.funding_type),callback:function ($$v) {_vm.$set(_vm.internalValue, "funding_type", $$v)},expression:"internalValue.funding_type"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":'Price per Share',"rules":{ required: false, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"Price per share (Optional)","color":"primary","error-messages":errors,"hide-details":errors.length === 0,"options":{ locale: 'en-US', precision: 2 },"prepend-inner-icon":"mdi-currency-usd"},model:{value:(_vm.internalValue.price_per_share),callback:function ($$v) {_vm.$set(_vm.internalValue, "price_per_share", $$v)},expression:"internalValue.price_per_share"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":'Pre-money valuation',"rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"Pre-money Valuation","color":"primary","options":{ locale: 'en-US', precision: 2 },"prepend-inner-icon":"mdi-currency-usd","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.internalValue.pre_money_valuation),callback:function ($$v) {_vm.$set(_vm.internalValue, "pre_money_valuation", $$v)},expression:"internalValue.pre_money_valuation"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"trsText--text text-caption text--light-1 mt-n4"},[_vm._v(" Pre-money valuation and price per share are required if issuing common shares and preferred shares and optional for convertibles and warrants. ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('span',{staticClass:"trsText--text font-weight-medium text-h4"},[_vm._v(" Close Financing Round ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":'Initial closing'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"error-messages":errors,"input-templ":{label: 'Initial closing', mandatory: true},"clearable":""},model:{value:(_vm.initialClosing),callback:function ($$v) {_vm.initialClosing=$$v},expression:"initialClosing"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":'Final closing'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"error-messages":errors,"input-templ":{label: 'Final closing', mandatory: false},"clearable":""},model:{value:(_vm.finalClosing),callback:function ($$v) {_vm.finalClosing=$$v},expression:"finalClosing"}})]}}])})],1)],1),_c('div',{staticClass:"trsText--text text-caption text--light-1 mt-n3"},[_vm._v(" You can add closing dates anytime after creating financing rounds. ")])],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('notes-and-documents-form',{attrs:{"id":_vm.financingRoundId,"mode":_vm.mode,"is-submit":_vm.isSubmit,"internal-dialog":_vm.internalDialog,"type":_vm.CONSTS.NOTES_DOCUMENT_API_TYPE.FINANCING_ROUNDS},on:{"navToList":function($event){return _vm.$emit('navToList', true)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }