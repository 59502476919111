<template>
  <v-row class="ma-0 mt-4">
    <v-col cols="6">
      <validation-provider
        v-slot="{ errors }"
        :name="'Financing Round Name'"
        :rules="{ required: true, max: 255 }"
      >
        <common-input-text-field
          v-model="internalValue.name"
          label="Financing round name"
          color="primary"
          :error-messages="errors"
          :hide-details="errors.length === 0"
        />
      </validation-provider>
    </v-col>
    <v-col cols="6">
      <validation-provider
        v-slot="{ errors }"
        :name="'Funding Type'"
        :rules="{ required: true }"
      >
        <common-input-select
          v-model="internalValue.funding_type"
          label="Funding Type"
          placeholder="Funding type"
          :items="CONSTS.FINANCING_ROUND_FUNDING_TYPES"
          :error-messages="errors"
          :hide-details="errors.length === 0"
        />
      </validation-provider>
    </v-col>
    <v-col cols="6">
      <validation-provider
        v-slot="{ errors }"
        :name="'Price per Share'"
        :rules="{ required: false, min_value: 0 }"
      >
        <common-input-currency-input
          v-model="internalValue.price_per_share"
          label="Price per share (Optional)"
          color="primary"
          :error-messages="errors"
          :hide-details="errors.length === 0"
          :options="{ locale: 'en-US', precision: 2 }"
          prepend-inner-icon="mdi-currency-usd"
        />
      </validation-provider>
    </v-col>
    <v-col cols="6">
      <validation-provider
        v-slot="{ errors }"
        :name="'Pre-money valuation'"
        :rules="{ required: true, min_value: 0 }"
      >
        <common-input-currency-input
          v-model="internalValue.pre_money_valuation"
          label="Pre-money Valuation"
          color="primary"
          :options="{ locale: 'en-US', precision: 2 }"
          prepend-inner-icon="mdi-currency-usd"
          :error-messages="errors"
          :hide-details="errors.length === 0"
        />
      </validation-provider>
    </v-col>
    <v-col cols="12">
      <div class="trsText--text text-caption text--light-1 mt-n4">
        Pre-money valuation and price per share are required if issuing common shares and preferred shares and optional for convertibles and warrants.
      </div>
    </v-col>
    <v-col cols="12">
      <div>
        <span class="trsText--text font-weight-medium text-h4">
          Close Financing Round
        </span>
        <v-row>
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              :name="'Initial closing'"
            >
              <input-template-date
                v-model="initialClosing"
                :mask="'##/##/####'"
                :error-messages="errors"
                :input-templ="{label: 'Initial closing', mandatory: true}"
                clearable
              />
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              :name="'Final closing'"
            >
              <input-template-date
                v-model="finalClosing"
                :mask="'##/##/####'"
                :error-messages="errors"
                :input-templ="{label: 'Final closing', mandatory: false}"
                clearable
              />
            </validation-provider>
          </v-col>
        </v-row>
        <div class="trsText--text text-caption text--light-1 mt-n3">
          You can add closing dates anytime after creating financing rounds.
        </div>
      </div>
    </v-col>
    <v-col cols="12">
      <notes-and-documents-form
        :id="financingRoundId"
        :mode="mode"
        :is-submit="isSubmit"
        :internal-dialog="internalDialog"
        :type="CONSTS.NOTES_DOCUMENT_API_TYPE.FINANCING_ROUNDS"
        @navToList="$emit('navToList', true)"
      />
    </v-col>
  </v-row>
</template>
<script>
  import moment from 'moment'
  import InputTemplateDate from '@/components/common/fmsvc/form-templates/InputTemplateDate'
  import NotesAndDocumentsForm from './NotesAndDocumentsForm.vue'

  export default {
    name: 'FinancingRoundCommonForm',

    components: {
      NotesAndDocumentsForm,
      InputTemplateDate,
    },

    props: {
      value: {
        type: Object,
        required: true,
      },
      mode: {
        type: String,
        default: 'create',
      },
      financingRoundId: {
        type: String,
      },
      isSubmit: {
        type: Boolean,
        default: false,
      },
      internalDialog: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      notesData: {},
      initialClosing: '',
      finalClosing: '',
    }),

    computed: {
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },
    watch: {
      value: {
        handler (val) {
          if (val) {
            this.initialClosing = this.internalValue.initially_closed_on ? moment(this.internalValue.initially_closed_on).format('MM/DD/YYYY') : ''
            this.finalClosing = this.internalValue.finally_closed_on ? moment(this.internalValue.finally_closed_on).format('MM/DD/YYYY') : ''
          }
        },
        immediate: true,
      },
      initialClosing: {
        handler (val) {
          if (val) {
            this.internalValue.initially_closed_on = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
      finalClosing: {
        handler (val) {
          if (val) {
            this.internalValue.finally_closed_on = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
    },
  }
</script>
