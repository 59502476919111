<template>
  <common-trs-page-container :padding="0">
    <template v-slot:page-content>
      <common-trs-card class="pa-2 pb-0 mb-2">
        <v-card-title class="mt-3">
          <span
            class="pr-3 cursor-pointer"
            @click="close"
          > <v-icon>
            mdi-arrow-left</v-icon></span> <span class="trsText--text font-weight-medium text-h4"> Edit Financing Round </span>
        </v-card-title>
        <financing-round-form
          :loading="loading"
          :financing-round="singleFinancingRound"
          :financing-round-id="id"
          :mode="'edit'"
          :internal-dialog="internalDialog"
          :is-submit="isSubmit"
          @save="update"
          @navToList="navToList"
        />
      </common-trs-card>
    </template>
  </common-trs-page-container>
</template>
<script>
  import * as captableService from '@/components/common/captable/services/captable'
  import FinancingRoundForm from './components/Form'
  export default {
    name: 'EditFinancingRound',
    components: {
      FinancingRoundForm,
    },
    data: () => ({
      loading: false,
      singleFinancingRound: {},
      isSubmit: false,
      internalDialog: false,
    }),
    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
      id () {
        return this.$route.query.id
      },
    },
    watch: {
      internalDialog: {
        handler (val) {
          if (val) {
            this.isSubmit = false
          }
        },
      },
    },
    mounted () {
      this.loadData()
    },
    methods: {
      async loadData () {
        this.loading = true
        try {
          const resp = await captableService.getFinancingRoundById(this.captableId, this.id)
          this.singleFinancingRound = resp.data
          this.internalDialog = true
          this.loading = false
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry! Unable to save financing round. Please try again later.',
          })
        }
      },
      async update (data) {
        this.loading = true
        this.clearNullData(data)
        try {
          const resp = await captableService.updateFinancingRound(this.captableId, this.id, data)
          if (resp.status === 204) {
            this.isSubmit = true
          }
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry! Unable to save financing round. Please try again later.',
          })
        }
      },
      navToList () {
        this.loading = false
        this.$store.dispatch('app/message', { text: 'Financing round saved successfully.' })
        this.$router.push({ name: 'FinancingRound' })
      },
      clearNullData (data) {
        Object.keys(data).forEach((key) => {
          if (data[key] === null || data[key] === undefined) {
            delete data[key]
          }
        })
      },
      close () {
        this.$router.push({ name: 'FinancingRound' })
      },
    },
  }
</script>
